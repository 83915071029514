<template>
  <div class="transaction-detial-box">
    <el-card class="box-card">
      <div slot="header">
        <div class="card-header">
          {{$t('transaction.交易')}}
        </div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('transaction.交易哈希')}}:</div>
        <div class="value">{{ transactionInfo.hash }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('transaction.状态')}}:</div>
        <div class="value">{{ transactionInfo.status ? $t('transaction.成功') : $t('transaction.失败') }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.区块')}}:</div>
        <div class="value">{{ transactionInfo.blockNumber }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('transaction.时间戳')}}:</div>
        <div class="value">
          {{
            dateFormat(
              "yyyy-MM-dd hh:mm:ss",
              new Date(transactionInfo.timestamp * 1000 || 0)
            )
          }}
        </div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('transaction.发送方')}}:</div>
        <div class="value">{{ transactionInfo.from }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex subset-wrap">
        <div class="title">{{$t('transaction.接收方')}}:</div>
        <div class="right">
          <div class="value" v-if="transactionInfo.to">{{ transactionInfo.to }}</div>
          <div v-else class="value">{{$t('transaction.创建合约')}} {{ transactionInfo.contractAddress }}</div>
          <div class="subset">
            <div class="value" v-for="(internal,index) in transactionInfo.internals" :key="index">{{$t('transaction.发送方')}}: 
           <router-link :to="{name: 'AddressDetial',params: { address:internal.from }
          }">{{internal.from}}</router-link> &nbsp;&nbsp;{{$t('transaction.接收方')}}:
          
          <router-link :to="{name: 'AddressDetial',params: { address:internal.to }
          }">{{internal.to}}</router-link>
          &nbsp;&nbsp;
          <router-link :to="{name: 'AddressDetial',params: { address:internal.contract }
          }">{{internal.value}}{{internal.token.symbol}}</router-link></div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('transaction.价值')}}:</div>
        <div class="value">
          {{ web3.utils.fromWei(transactionInfo.ethValue || "0", "ether") }} ds
        </div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('transaction.交易费用')}}:</div>
        <div class="value">
          {{
            web3.utils.fromWei(
              `${transactionInfo.gasPrice * transactionInfo.gasUsed || 0}`,
              "ether"
            )
          }}
          ds
        </div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.燃料价格')}}:</div>
        <div class="value">
          {{ web3.utils.fromWei(transactionInfo.gasPrice || "0", "Gwei") }} Gwei
        </div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('block.燃料限制')}}:</div>
        <div class="value">{{ transactionInfo.gas }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">nonce:</div>
        <div class="value">{{ transactionInfo.nonce }}</div>
      </div>
      <el-divider></el-divider>
      <div class="item flex-middle">
        <div class="title">{{$t('transaction.数据输入')}}:</div>
        <div class="value">{{ transactionInfo.input }}</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { getTransactionInfo } from "@/api";
import { useI18n } from "vue-i18n"
export default {
  components: {},
  setup () {
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n()
    const data = reactive({
      $t: t,
      hash: route.params.hash,
      transactionInfo: {}
    });
    onMounted(() => {
      getTransactionInfo({ hash: data.hash }).then(res => {
        data.transactionInfo = res.data;
      });
    });
    onBeforeRouteUpdate((to, from) => { });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>

<style lang="scss" scoped>
.card-header {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}
.item,
.subset-wrap {
  font-size: 16px;
  color: #333;
  text-align: left;
  .title {
    width: 20%;
  }
  .value {
    flex: 1;
    word-break: break-all;
  }
}
</style>
